<template>
  <v-container v-if="!loading" class="mb-6">
    <v-img
      src="https://storage.googleapis.com/img.fod.live/static/app/food/nick-karvounis-381267.jpg"
      height="500"
      contain
    >
    </v-img>
    <v-layout column align-center justify-center>
      <v-flex xs12 sm6>
        <h2 class="blue--text mb-2 display-1 text-center">
          Change your profile to Chef
        </h2>
      </v-flex>
    </v-layout>
    <v-layout column align-center justify-center>
      <v-flex xs12 sm6 class="subheading  text-center">
        <p>
          Your current profile is not set as <b>chef</b>. <br />If you love
          cooking and want to be a food provider, then submit request for a chef
          account. <br />However, being a chef requires a big commitment and
          responsibility.
        </p>
      </v-flex>
    </v-layout>
    <v-layout column align-center justify-center>
      <v-flex xs12 sm6>
        <v-checkbox
          v-model="agree"
          color="light-green"
          label="Do you agree?"
          required
        ></v-checkbox>
      </v-flex>
    </v-layout>
    <v-layout column align-center justify-center>
      <v-flex xs12 class="mb-3">
        <v-btn
          :disabled="!agree"
          class="success mt-5"
          dark
          large
          @click.prevent.stop="applyForChef"
        >
          Apply
        </v-btn>
      </v-flex>
      <v-flex xs12 xs6 v-if="applied">
        <v-alert :value="true" outlined color="black" icon="info"
          >Your request has been submitted. For a meantime you can visit
          <a :href="chefPortalLink">{{ chefPortalLink }}</a> to check your
          approval status.</v-alert
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    agree: false,
    applied: false
  }),
  computed: {
    ...mapGetters({
      // chefPortalAddress: 'chefPortalAddress',
      loading: 'loading'
    })
  },
  methods: {
    applyForChef() {
      console.log('Your chef request submitted')
      this.$store.dispatch('applyForChef')
      this.applied = true
    }
  }
}
</script>
